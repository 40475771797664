<template>
  <div>
    <b-card>
      <template #header>
        <div
          class="d-flex justify-content-center"
          style="font-size: 20px"
        >
          Farmbusiness Software 🍃
        </div>
      </template>
      <b-card-text>APP VERSION: 1.0.0</b-card-text>
      <b-card-text>LAST UPDATED: 20TH AUG 2023</b-card-text>
      <b-card-text>SUPPORT PHONE: 023390299399</b-card-text>
      <b-card-text>SUPPORT EMAIL: farmbusiness@gmail.com</b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  mounted() {
    this.$store.dispatch('organization/fetchOrganizations')
  }
}
</script>

<style>

</style>
